import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import wc from './img/wc.svg';
import { QRCodeCanvas } from 'qrcode.react';

export default function ProposalModal({ visible, onClose, uri }) {
  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="relative w-full h-full max-w-md h-auto mx-4 md:mx-0">
        <div className="relative bg-gray-100 rounded-lg shadow">
          <div className="flex items-center justify-between px-5 border-gray-500">
            <img src={wc} className="h-16 w-40" />
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
              className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
              data-modal-hide="small-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="px-3 py-6 border-t mx-6 space-y-6">
            <div className="flex flex-col space-y-4 items-center space-x-2 border-gray-600 rounded-b justify-center mx-6">
              <QRCodeCanvas value={uri} size={168}></QRCodeCanvas>
            </div>

            <div>
              <p className="text-center leading-relaxed text-xs text-gray-800 break-words">{uri}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
