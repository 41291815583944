/**
 * Wallet Connect Facade Object
 * Biraz zorlama oldu ama arada zorlamak lazim :)
 */

import SignClient from '@walletconnect/sign-client';
import { SessionTypes } from '@walletconnect/types';

// TODO envvar
const PROJECT_ID = 'd632fc4c8784787b38046229cb9c2946';

const WCFacade: {
  signClient?: SignClient;
  uri?: string;
  init: () => Promise<void>;
  connect(): Promise<{ uri: string | undefined; approval: () => Promise<SessionTypes.Struct> }>; // :)
} = {
  signClient: undefined,
  uri: undefined,
  init: async function () {
    SignClient.init({ projectId: PROJECT_ID }).then((client) => {
      this.signClient = client;
      console.log('Sign client inited');

      this.signClient.on('session_ping', () => {
        console.log('session ping geldi');
      });

      this.signClient.on('session_event', (value) => {
        console.log('session event geldi');
        console.log(value);
      });

      this.signClient.on('session_proposal', (value) => {
        console.log('session proposal geldi');
        console.log(value);
      });

      console.log('client events registered');
    });

    // Problemsiz calisiyor
  },
  connect: async function () {
    if (this.signClient === undefined) throw new Error('Sign client not inited');

    const { uri, approval } = await this.signClient.connect({
      requiredNamespaces: {
        eip155: {
          methods: ['eth_sign'],
          chains: ['eip155:12345'],
          events: ['accountsChanged', 'connect', 'disconnect'],
        },
      },
    });
    this.uri = uri;

    //? Burasi calismiyor, niye?
    // approval().then((session) => {
    //   console.log('WCFacade: session olustu');
    //   console.log(session);
    // });

    return { uri, approval }; // TODO type assertation
  },
};

export { WCFacade };
